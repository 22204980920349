import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/Layout"
import Navigation from '../navigation'
import { Helmet } from "react-helmet"

export default function AboutRoute(props: PageProps) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>U.I.WD. - About</title>
        <link rel="icon" type="image/png" href="/favicon.ico"></link>
        <meta property="og:site_name" content="U.I.WD. Creative Studio" />
          <meta property="og:title" content="U.I.WD. Creative Studio" />
        <meta property="og:url" content="https://uiwd.co" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/share.png" />
        <meta name="twitter:title" content="U.I.WD Creative Studio" />
        <meta name="twitter:image" content="/share.png" />
        <meta name="twitter:url" content="https://uiwd.co" />
        <meta name="twitter:card" content="summary" />
        <meta name="description" content="U.I.WD. is a creative direction studio based in São Paulo. Specialising in Art Direction, Digital, Product, Print and Space." />
      </Helmet>
      <Layout>
        <Navigation about></Navigation>
      </Layout>
    </>
  )
}
